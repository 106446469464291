<script>
  import { route, conf, modal, userId, user, customerId, vendorId, alert } from './stores.js'
  import { setRoute, fuc, daysLeft, u, clipBoard, logout, asArray, i18n, api, apiGet, apiPost, apiDelete, dateTimeFormat, sleep, genCode, cdnDocument } from './h.js'
  import Alert from './G_Alert.svelte'
  import I18n from './G_I18n.svelte'
  import I from './G_I.svelte'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import LineMenu from './G_LineMenu.svelte'
  import Iban from './G_Iban.svelte'
  import Route from './G_Route.svelte'
  import Invoices from './G_Invoices.svelte'
  import PricingGrid from './G_PricingGrid.svelte'
  import Wrong from './G_Wrong.svelte'
  import List from './G_List.svelte'
  import FilterLine from './G_FilterLine.svelte'
  import Licence from './L_Licence.svelte'
  
  const [valid, customerSelector, isVendor, isCustomer] = Array(4).fill(true);

  $: menus=[
    //{ route: '/vendor/dashboard', name: 'dashboard', roles:['v-manager','v-admin'] },
    { route: '/vendor/users', name: 'users', roles:['v-manager1','v-admin'], avalid:{c:['admin'], v:['admin', 'manager']} },
    { route: '/vendor/customers', name: 'customers', roles:['v-manager'] },
    { route: '/vendor/informations', name: 'informations', roles:['v-admin'] },
    { route: '/vendor/design', name: 'design', roles:['v-admin'] },
    //{ route: '/vendor/licence', name: 'design', roles:['v-admin'] },
    { route: '/vendor/billing', name: 'billing', roles:['v-admin'] },
    { route: '/vendor/support', name: 'support'}
  ]
 
  let obj={}, objs={}, listCustomersKeyword="", listCustomersSortBy={name:1}, listCustomersFilter={valid:true}
  let listsReload=0

  $: if($route==`/vendor/design` && obj) {
    $conf={...$conf, ...cdnDocument(obj)} //-> it works to change design in real time but need to rollaback if not saved
  }


  async function listUsers(){
    return await api(`/app/vendor/users/list`)
  }

  async function getVendor(){
    obj = await apiGet(`/vendors/${$vendorId}`)
  }

  async function setVendor(){
    const ret=await apiPost(`/vendors`,obj)
    $conf={...$conf, ...cdnDocument(obj)}
    setRoute(`/vendor/informations`)
    $alert.success="Updated"
  }

  async function addCustomer(){
    obj={}
    setRoute(`/vendor/customers/edit`)
  }

  async function editCustomer(id=''){
    setRoute(`/vendor/customers/edit/${id}`)
  }
  

  async function listCustomers(...checkArgs){
    return await api(`/app/vendor/customers/list`, {request:listCustomersFilter, keyword:listCustomersKeyword,sortBy: listCustomersSortBy})
  }

  async function getCustomer(id){
    if(id) obj = await api(`/app/vendor/customers/get/${id}`)
    if(!obj) obj={}
  }

  async function setCustomer(){
    await api(`/app/vendor/customers/set`,{obj: obj})
    //$customers = await api('/app/users/customers/list')  //remplacer par getuser
    setRoute(`/vendor/customers`)
  }

  async function delCustomer(){
    $modal={cancel:true, title:`${i18n("g--Delete")}  ${obj.name}`, msg:i18n("vendor--delete customer confirm"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      let data=await api(`/app/vendor/customers/delete/${obj.id}`,{spinner:true})
      //$customers=false    //remplacer par getuser
      setRoute(`/vendor/customers`)
    }}
  }
    
  async function sendCredential(user){
    if(!user) return
    let data=await api('/app/users/credential/send',{authId:user.email})
    if(data) $alert.success=`${i18n("account--Credential sent to")} ${user.email}`
  }

  async function addUser(){
    obj={perms:{valid:true}}
    setRoute("/vendor/users/add")
  }

  async function setUser(){
    await api(`/app/vendor/users/set`,{obj: obj})
    setRoute("/vendor/users")
  }

  async function delUser(user){
    $modal={cancel:true, title:`${i18n("Delete")}  ${user.name || user.email}`, msg:i18n("account--delete member"), color:'danger', dangerZone:'yes', confirm:async ()=>{
      await api(`/app/vendor/users/delete/${user.id}`)
      setRoute("/vendor/users")
    }}
  }

  async function setUserPermissions(user){
    await sleep(100) //needed to permit to perms checkbox to propagate !
    await api(`/app/vendor/users/set`,{obj: user})
  }
</script>

<div class="container-lg fsContent">

  <Title title back/>
   
  <LineMenu {menus} baseRoute="/vendor"/>

  <Route path="/vendor/users" fsContent menuRoles={menus}>
    {#await listUsers() then objs}
      <div class="fsContentHorizontal fs-5 my-1 ">
        <div>{asArray(objs).length} <I18n>vendor--Users</I18n></div>
        <Button add={()=>addUser()} title="vendor--add member"/>
      </div>
            
      <div class="fsScroll">
        <div class="cards">
          {#each asArray(objs) as objuser (objuser)}
            <div class="card shadow">
              <div class="card-header">
                <div class="fsContentHorizontal">
                  <div class="fs-5 fw-bold">{u(objuser.name || objuser.email.split('@')[0])}</div>
                  {#if $userId==objuser.id}({i18n("Me")}){/if}
                  <Input set={false} type="switch" disabled={$userId==objuser.id} option="g--Active" bind:value={objuser.perms.valid} change={()=>setUserPermissions(objuser)}/>
                </div>
                <div class="small">{u(objuser.email)}</div>
              </div>
              <div class="card-body">
                {#if $user.perms.admin}
                  <Input set={false} type="switch" disabled={$userId==objuser.id} option="g--Admin" bind:value={objuser.perms.admin} change={()=>setUserPermissions(objuser)}/>
                {/if}
                {#each $conf.permsVendor as p}
                  {#if $user.perms[p.id] || $user.perms.admin}
                    <Input set={false} type="switch" disabled={$userId==objuser.id || objuser.perms.admin} option={p.name} bind:value={objuser.perms[p.id]} change={()=>setUserPermissions(objuser)}/>
                  {/if}
                {/each}
                <!--Input set={{}} type="checkbox" disabled={($userId==objuser.id || !objuser.perms.valid)} options={$conf.permsVendor} bind:value={objuser.perms} change={()=>setUserPermissions(objuser)}/-->
              </div>
              {#if $userId!=objuser.id}
                <div class="card-footer fsContentHorizontal">
                  {#if asArray(objs).length>1} 
                    <Button del={()=>delUser(objuser)}/>
                  {/if}
                  <div class="pointer" on:click={()=>sendCredential(objuser)}><i class="{$conf.faType} fa-key pointer"></i> <I18n>Send credentials</I18n></div>
                </div>
              {/if}
            </div>
          {/each}
        </div>
      </div>
    {/await}
  </Route>
  
  <Route path="/vendor/users/add" fsContent menuRoles={menus}>
    <Title title="vendor--add member" cancel="/vendor/users"/>
    <Form>
      <Input bind:value={obj.email} icon type="email" label="email" required/>
      {#if $user.perms.admin}
        <Input set={false} type="switch" option="g--Admin" bind:value={obj.perms.admin}/>
      {/if}
      {#each $conf.permsVendor as p}
        {#if $user.perms[p.id] || $user.perms.admin}
          <Input set={false} type="switch" disabled={obj.perms.admin} option={p.name} bind:value={obj.perms[p.id]}/>
        {/if}
      {/each}
  
  
      <!--Input set={{valid:true}} type="checkbox" options={$conf.permsVendor} bind:value={obj.perms}/-->
      <Button validity action={setUser} actionModel="add" class="col-auto"/>
    </Form>
  </Route>

  <Route path="/vendor/customers/*" pathHide="/vendor/customers/+" fsContent menuRoles={menus}>
    <FilterLine search bind:keyword={listCustomersKeyword} create={()=>addCustomer()}
      bind:filter={listCustomersFilter} filters={[
        { filter: {valid:true}, title:'g--In use' },
        { filter: {expired:true}, title:'g--Expired' },
        { filter: {valid:false}, title:'g--Not in use' },
      ]}

      bind:sortBy={listCustomersSortBy} sortBys={[
        { id:'name', title:'g--By name'},
        { id:'licence', title:'g--By licence'},
        { id:'expireDate', title:'g--By expiration date'},
      ]}
    />
    {#await listCustomers(listCustomersKeyword, listCustomersSortBy, listCustomersFilter, listsReload) then objs}
      <List onSelect={editCustomer} refresh={()=>listsReload++} title="g--customers" {objs} fields={[
        {id:"name", title:"g--Name"},
        {id:"valid", title:"g--In use"},
        {id:"expired", title:"g--Expired"},
        {id:"expireDate", title:"g--Expire date", format:"dt"},
        {id:"licence", title:"g--Licence"}
      ]}/>
    {:catch}
      <Wrong/>
    {/await}
  </Route>

  <Route path="/vendor/customers/edit/:id?" let:routeParams fsContent menuRoles={menus}>
    {#await getCustomer(routeParams.id) then}

      <Title title={i18n("vendor--Edit customer") + ' ' + obj.name} cancel="/vendor/customers"/>
      <div class="fsScroll">
        <Form>
          {#if obj.cDate}
            <Input class="col-sm-12" bind:value={obj.cDate} icon type="datetime" label="Create date" readonly/>
          {/if}
          <Input set={false} lg noLabel class="col-sm-2" type="switch" option="g--Active" bind:value={obj.valid}/>
          <Input set={genCode(5)} class="col-sm-2" readonly bind:value={obj.shortId} label="reference" icon required/>
          <Input bind:value={obj.name} class="col-sm-5" label="company" icon required/>
          <Input bind:value={obj.taxId} class="col-sm-3" label="VAT number" icon="taxid"/>
          <Input bind:value={obj.email} class="col-sm-6" type="email" label="email" icon/>
          <Input bind:value={obj.phone} class="col-sm-6" type="tel" label="phone" icon/>
          <Input bind:value={obj.address} class="col-sm-8" label="address" icon/>
          <Input bind:value={obj.zip} class="col-sm-4" label="zip" icon/>
          <Input bind:value={obj.city} class="col-sm-4" label="city" icon/>
          <Input bind:value={obj.state} class="col-sm-4" label="state" icon/>
          <Input bind:value={obj.country} class="col-sm-4" label="country" icon/>

          {#if $conf.vendor?.withSponsorship}
            <Input set={genCode(10)} class="col-sm-4" readonly bind:value={obj.sponsorshipId} label="sponsorship id" icon="fa-hands-holding-diamond" required/>
          {/if}
          
          {#if $conf.vendor?.withLicenceVendorCustomer}
            <Input type="hr" icon="fa-info-square" label="Licence"/>
            <Input class="col-sm-4" set={new Date().setDate(new Date().getDate() + 7)} bind:value={obj.expireDate} icon type="datetime" label="Expire date" required/>
            <Input class="col-sm-4" bind:value={obj.expired} set={false} lg type="switch" icon="fa-triangle-exclamation" label="Expired" option="oui"/>


            <Input bind:value={obj.paymentType} set={"other"} inline type="radio" icon="fa-bank" label="Billing type" options={[ 
              { value: "other", label: "other"},
              { value: "card", label: "card"},
              { value: "debit", label: "debit"},
              { value: "check", label: "check"},
              { value: "transfer", label: "transfer"}
            ]}/>

            <Input class="col-sm-12" bind:value={obj.paymentAuto} set={false} lg type="switch" icon="fa-triangle-exclamation" label="Payment auto" option="oui"/>

            <Input bind:value={obj.period} set={"yearly"} disabled={obj.paymentAuto} inline type="radio" icon="fa-tags" label="Billing period" options={[ 
              { value: "year", label: "yearly"},
              { value: "month", label: "monthly"}
            ]}/>

            <Input bind:value={obj.price} set={0} disabled={obj.paymentAuto} class="col-sm-12" label="Price" icon/>
          {/if}

          {#if $conf.vendor?.withLicenceVendorCustomerDetail}
            <Licence bind:obj select="vendorCustomer"/>
          {/if}

          {#if obj.id}
            <Button validity action={delCustomer} actionModel="delete" class="col-auto"/>
            <Button validity action={setCustomer} actionModel="update" class="col-auto"/>
          {:else}
            <Button validity action={setCustomer} actionModel="save" class="col-auto"/>
          {/if}
        </Form>
      </div>
    {:catch}
      <Wrong/>
    {/await}
  </Route>
          


  <Route path="/vendor/informations" fsContent menuRoles={menus}>
    {#await getVendor() then}
      <div class="fsScroll">
        <Form>
          <Input readonly bind:value={obj.shortId} class="col-sm-2" label="reference" icon required/>
          <Input bind:value={obj.name} class="col-sm-6" label="company" icon required/>
          <Input bind:value={obj.taxId} class="col-sm-4" label="VAT number" icon="taxid"/>
          <Input bind:value={obj.email} class="col-sm-6" type="email" label="email" icon/>
          <Input bind:value={obj.phone} class="col-sm-6" type="tel" label="phone" icon/>
          <Input bind:value={obj.address} class="col-sm-8" label="address" icon/>
          <Input bind:value={obj.zip} class="col-sm-4" label="zip" icon/>
          <Input bind:value={obj.city} class="col-sm-4" label="city" icon/>
          <Input bind:value={obj.state} class="col-sm-4" label="state" icon/>
          <Input bind:value={obj.country} class="col-sm-4" label="country" icon/>

          <Alert local={{noDismiss:true, warning:"vendor--Define a support"}}/>
          <Input set={"support"} bind:value={obj.supportName} class="col-sm-6" label="g--name" icon="name" required/>
          <Input bind:value={obj.supportEmail} class="col-sm-6" label="g--email" icon="name" required/>
          <Input bind:value={obj.supportPhone} class="col-sm-6" label="g--phone" icon="name" required/>
          <Input set={false} type="switch" class="col-sm-6" label=" " option="g--use my SMTP mail server" bind:value={obj.isSmtpServer}/>
          
          {#if obj.isSmtpServer}
            <Input set={"support@mydomain.zzz"} bind:value={obj.supportEmail} type="email" class="col-sm-6" label="g--email" icon="email" required/>
            <Input set={"smtp.mydomain.zzz"} bind:value={obj.smtpServer} type="fqdn" class="col-sm-4" label="g--server" icon="fa-server" required/>
            <Input set={25} bind:value={obj.smtpPort} type="number" class="col-sm-2" label="g--port" icon="fa-plug" required/>
            <Input set={"user"} bind:value={obj.smtpAccount} type="text" class="col-sm-6" label="g--vendor" icon="fa-user"/>
            <Input set={"password"} bind:value={obj.smtpPassword} type="password" noPattern class="col-sm-6" label="g--password" icon="fa-key"/>
          {/if}

          <Input set={false} type="switch" class="col-sm-6" noLabel lg option="g--signInOpen" bind:value={obj.signInOpen}/>
          <Input set={false} type="switch" class="col-sm-6" noLabel lg option="g--signOtp" bind:value={obj.signOtp}/>
          <Input set={false} type="switch" class="col-sm-6" noLabel lg option="g--signUpOpen" bind:value={obj.signUpOpen}/>
          <Input set={false} type="switch" class="col-sm-6" noLabel lg option="g--sign2FA" bind:value={obj.sign2FA}/>

          <Alert local={{noDismiss:true, warning:"vendor--Define a DPO"}}/>
          <Input bind:value={obj.dpoEmail} type="email" class="col-sm-6" label="Dpo email" icon="email"/>
          <Input bind:value={obj.dpoName} class="col-sm-6" label="Dpo name" icon="name"/>
          <Input bind:value={obj.dpoLink} type="url" class="col-sm-12" label="Dpo link" icon="link"/>
          <Button validity action={setVendor} actionModel="update" class="col-auto"/>
        </Form>
      </div>
    {:catch}
      <Wrong/>
    {/await}
  </Route>

  <Route path="/vendor/design" fsContent menuRoles={menus}>
    {#await getVendor() then}
      <div class="fsScroll">
        <Form>
          <Input class="col-sm-4" set="" bind:value={obj.logo} accept="image/png,image/jpeg,image/gif" type="file" label="g--Logo" icon/>
          <Input class="col-sm-4" set="" bind:value={obj.icon} accept="image/png,image/jpeg,image/gif" type="file" label="g--Icon" icon/>
          <Input class="col-sm-4" set="" bind:value={obj.splash} accept="image/png,image/jpeg,image/gif" type="file" label="g--Splash" icon/>
          
          <Input class="col-sm-6" set="#E2E2E2" bind:value={obj.style.brandColor} type="color" smooth colorText label="g--brandColor"/>
          <Input class="col-sm-6" set="#DAFFC3" bind:value={obj.style.brandColor2} type="color" smooth colorText label="g--brandColor2"/>
          
          <Input class="col-sm-4" set="#E2E2E2" bind:value={obj.style.authBgColor} type="color" smooth colorText label="g--authBgColor"/>
          <Input class="col-sm-4" set="#E2E2E2" bind:value={obj.style.navbarBgColor} type="color" smooth colorText label="g--navbarBgColor"/>
          <!--Input class="col-sm-4" set="#000000" bind:value={obj.style.navbarTextColor} type="color" smooth colorText label="g--navbarTextColor"/-->
          <!--Input class="col-sm-4" set="1rem" bind:value={obj.style.navbarFontSize} type="text" smooth colorText label="g--navbarFontSize"/-->
          <Input class="col-sm-4" set="#000000" bind:value={obj.style.color} type="color" smooth colorText label="g--color"/>
        
          <Input class="col-sm-4" set="#339999" bind:value={obj.style.buttonBgColor} type="color" smooth colorText label="g--buttonBgColor"/>
          <Input class="col-sm-4" set="#FFFFFF" bind:value={obj.style.buttonTextColor} type="color" smooth colorText label="g--buttonTextColor"/>

          <Input empty/>

          <Button validity action={setVendor} actionModel="update" class="col-auto"/>
        </Form>
      </div>
    {:catch}
      <Wrong/>
    {/await}
  </Route>

  <Route path="/vendor/licence" fsContent menuRoles={menus}>
    <I18n>licence--Your</I18n>
    {#if obj.expireDate}
      <div class="card-title"><I18n>licence--Expiration date</I18n>: {dateTimeFormat(obj.expireDate, 'complete')}</div>
      <p class="card-text"><I18n>licence--Days left</I18n>: {daysLeft(obj.expireDate)}</p>
    {/if}
  </Route>

  <Route path="/vendor/billing" fsContent menuRoles={menus}>
    <div class="fsScroll">
      <!--Form>
        <Iban/>
      </Form-->
      {#await api(`/app/vendor/invoices/list`) then invoices}
        <Invoices {invoices}/>
      {/await}
    </div>
  </Route>  

  <Route path="/vendor/support" fsContent menuRoles={menus}>
    <h5><I18n>vendor--Your support</I18n></h5>
    {#if $conf.support}
      <p>{u($conf.support.email)}</p>
      <p>{u($conf.support.phone)}</p>
      <p>{u($conf.support.text)}</p>
    {/if}
    <p>Version V{$conf.version} - Base {fuc($conf.htmlRoot)} - Code {fuc($conf.serviceCode)} </p>
  </Route>

</div>