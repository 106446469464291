<script>
  import { lang } from './stores.js'
  import { i18n } from './h.js'
  import Input from './G_Input.svelte'
  import I18n from './G_I18n.svelte'
  export let select=""
  export let obj={}
  if(!obj.licenceDetails) obj.licenceDetails={}
</script>

<!-- in vendor customer licence screen -->
{#if select=="vendorCustomer"}

  <Input class="col-sm-12" bind:value={obj.upgrade} set={true} lg type="switch" icon="fa-triangle-exclamation" label="Customer can upgrade" option="oui"/>

  <Input bind:value={obj.licence} set={"LITE_01"} inline type="radio" icon="fa-tags" label="Licence" options={[ 
    { value: "LITE_01", label: "LITE"},
    { value: "CUSTOM", label: "CUSTOM"}
  ]}/>

  {#if obj.licence=="CUSTOM"}
    <Input bind:value={obj.licenceDetails.cars} class="col-sm-6" set={5} min=0 max=1000 type="number" icon label="Number of cars"/>
    <Input bind:value={obj.licenceDetails.users} class="col-sm-6" set={5} min=1 max=1000 type="number" icon label="Number of users"/>
  {/if}

{/if}



<!-- in account customer licence screen -->
{#if select=="accountCustomer"}

  {#if obj.licence!='NONE'}
    <hr>
    <div class="fw-bold"><I18n>licence--Type</I18n></div>
    {obj.plan?.name || obj.licence}
  {/if}

  <hr>
  <div class="fw-bold"><I18n>licence--Usage</I18n></div>
  <div><I18n id="cars"/> {obj.usages.cars} / {obj.provision.cars}</div>
  <div><I18n id="Users"/> {obj.usages.users} / {obj.provision.users}</div>
{/if}