<script>
  import { conf, route, menus, user, customerId } from './stores.js'
  import { asArray, matchRoute, fuc } from './h.js'

  $: showCustomerSelector=$menus.find(menu => menu.customerSelector && matchRoute($route, menu.route+'/*'));

</script>

<div class="me-2 customerSelectShow w-xxl" class:invisible={!showCustomerSelector}>
  {#if asArray($user.customers).length>1 && !$conf.asCustomerId}
  <select class="form-select" bind:value={$customerId}>
    {#each asArray($user.customers) as customer}
    <option value={customer.id} select={$customerId==customer.id}>
      {fuc(customer.workspace || customer.name)}
    </option>
    {/each}
  </select>
  {:else }
    <div class="fs-4">{fuc(asArray($user?.customers)?.[0]?.workspace ||  asArray($user?.customers)?.[0]?.name)}</div>
  {/if}
</div>

<style>
   .customerSelectShow {
    visibility: visible;
  }
  @media (min-width: 1400px) {
    .w-xxl {
      width: 20% !important;
    }
  }
  .w-xxl {
      width: 50%;
    }
</style>
