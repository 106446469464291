<script>
  import { fade, scale, fly, slide } from 'svelte/transition';
  
  import { permissions } from './stores.js'
  import { route, routeMatched, routeReload } from './stores.js'
  import { routeU, routeMatchedU, routeReloadU } from './stores.js'
  import { matchRoute, isRouteRoot, checkPermsMenu, checkRoles } from './h.js'

  export let isU = false // use the second route (U)

  export let comment = false // for debug
  export let noMatch = false // if no other route is matched
  export let noCountMatch = false // don't count as a matched route
  export let path = false //can be one path or array of path
  export let pathHide = false //can be one path or array of path
  export let root = false //match if route match root
  export let notRoot = false //match if route doesn't match root
  export let valid = true
  export let menuRoles = false
  export let roles = false
  export let validHide = false
  export let top = false //scroll page to top
  export let Fade = false
  export let Scale = false
  export let Fly = false
  export let Slide = false
  export let Blur = false
  export let out = false //bug : apply transition to out but error in svelte sometimes

  export let fsContent = false //add fsContent class (flex for scrolling in childs)

  let cl = $$props.class || ''

  $: if (comment) console.log("@G_Route comment:", comment)


  let matched, isMatch, isMatchHide, show, butHide

  //verify and prepare
  $: {
    //import roles of menus
    if (menuRoles && valid) {
      valid = false
      for (let menu of menuRoles) {
        //console.log(matchRoute(path, menu.route+'/*') ,path, menu.route, checkPermsMenu(menu, $permissions))
        if (matchRoute(path, menu.route+'/*') && checkPermsMenu(menu, $permissions)) {
          valid = true
          break;
        }
      }
    }

    if (checkRoles && valid) {
      valid = false
      if (checkRoles(roles, $permissions)) valid = true;
    }

    matched = matchRoute(isU ? $routeU : $route, path)
    isMatch = valid && ((path && matched.match) || (!path && !noMatch && !root && !notRoot) || (root && isRouteRoot(isU ? "U" : "")) || (notRoot && !isRouteRoot(isU ? "U" : "")))
    isMatchHide = valid && (validHide || (pathHide && matchRoute(isU ? $routeU : $route, pathHide).match))

    //at least one route is globally matched
    if (isMatch && !noCountMatch) isU ? $routeMatchedU = true : $routeMatched = true

    //finally decide if we show route, and butHide (display none)
    show = isMatch || (!(isU ? $routeMatchedU : $routeMatched) && noMatch)
    butHide = isMatchHide || validHide

    //and put on top if requested
    if (show && top && typeof window != 'undefined') window.scroll(0, 0)
  }


  const transIn = (node) => {
    if (Fade) return fade(node, Fade)
    if (Scale) return scale(node, Scale)
    if (Fly) return fly(node, Fly)
    if (Blur) return blur(node, Blur)
    if (Slide) return slide(node, Slide)
    //return ()=>{}
  }

  //out:transOut lead to tick bug ???
  const transOut = (node) => {
    if (out) {
      if (Fade) return fade(node, Fade)
      if (Scale) return scale(node, Scale)
      if (Fly) return fly(node, Fly)
      if (Blur) return blur(node, Blur)
      if (Slide) return slide(node, Slide)
    }
    //return ()=>{}
  }

  // /test  -> show
  // /test/*  -> show
  // /test/step/* -> show
  //pathHide=/test/step/2 -> hide
  //path=/test/toto/*/:id?/:uuid  -> dynamic var are set in {routeParams}
  /* example :
  <Route path="/register/*" pathHide="/register/visitor" top let:routeParams>
    hello register test
    <Cards {routeParams}/>
  </Route>
  */
</script>

{#if !isU}
  {#key $routeReload}
    {#if show }
      <div hasmatched={matched.path} class="G_Route {cl}" class:fsContent in:transIn class:hideMe={butHide}>
        <slot routeParams={matched.routeParams} />
      </div>
    {/if}
  {/key}
{/if}

{#if isU}
  {#key $routeReloadU}
    {#if show }
      <div hasmatched={matched.path} class="G_Route {cl}" class:fsContent in:transIn class:hideMe={butHide}>
        <slot routeParams={matched.routeParams} />
      </div>
    {/if}
  {/key}
{/if}

<style>
  .hideMe {
    display: none !important;
  }
</style>