<script>
  import { customerId, route, routeReload, conf, token, user, userId, wsc, menus, permissions } from './stores.js'
  import { checkPermsMenu, matchRoute, setRoute, i18n, init, setCssVars, apiGet, logout, thisYear }  from './h.js'  
  
  
  import Auth from './G_Auth.svelte'

  //import FontSelector from './G_FontSelector.svelte'
  import Offline from './G_Offline.svelte'
  import SpinnerGlobal from './G_SpinnerGlobal.svelte'
  import NavBarMenu from './G_NavBarMenu.svelte'
  import DivButtonMenu from './G_DivButtonMenu.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'
  import Modal from './G_Modal.svelte'
  import Alert from './G_Alert.svelte'
  import Route from './G_Route.svelte'
  import I18n from './G_I18n.svelte'
  import I from './G_I.svelte'

  import Profil from './G_Profil.svelte'
  import Translations from './G_Translations.svelte'
  import Account from './G_Account.svelte'
  import Vendor from './G_Vendor.svelte'

  let showNavSided = false;
  $: if ($routeReload || $route) showNavSided = false;
  export let localConf = {}

  init(localConf)
  $: setCssVars($conf.style)

  $: if (!$token) setRoute('/auth/signIn')
  $: if (!$userId && $token) auth()

  async function auth() {
    if (!$userId && $token) {
      let data = await apiGet(`/users/me`, {}, { spinner: true })
      if (data) {
        $user = data
        $wsc.subscribe('user_' + $user.id)
        setRoute()
        return
      }
    }
    setRoute('/auth/signIn')
  }

  //direct to the right default route when perm change
  $: setBaseRoute($permissions)
  function setBaseRoute(){
    if(!$userId) return
    let firstValid
    for (let menu of $menus) {
      if(checkPermsMenu(menu, $permissions)){
        if(!firstValid) firstValid=menu.route
        if(menu.route==$route || matchRoute($route, menu.route+ '/*') ) return
      }
    }
    if(firstValid) setRoute(firstValid, true)
  }

</script>

<!-- global -->

<!--FontSelector/-->
<Offline/>
<SpinnerGlobal/>
<Modal/>

<!-- signin -->
<Route path="/auth/*">
  <Auth/>
</Route>


<!-- full screen app -->
<Route valid={$userId}>
  {#key $customerId}
  <div class="fs">
    <!-- menu -->
      <NavBarMenu sideBreak='xxl' widthCustomerSelector=20 signOutFct={logout}/>
      <CustomerBanner/>

    <!-- global alert -->
    <Alert class="container-lg"/>

    <!-- content -->
    <div class="fsContent"> 

      <slot/>
      

      <Route path="/profil/*" fsContent>
        <Profil/>
      </Route>

      <Route path="/translations/*" fsContent>
        <Translations/>
      </Route>

      <Route path="/account/*" fsContent>
        <Account/>
      </Route>

      <Route path="/vendor/*" fsContent>
        <Vendor/>
      </Route>      


    </div>

    <!-- footer -->
    <div class="footer small fsContentHorizontal justify-content-around p-2">
      <div><i class="{$conf.faType} fa-copyright"></i> {thisYear()} {$conf.title} - V{$conf.version}</div>
    </div>

  </div>
  {/key}
</Route>


<style>
  :global(body){
    background-color:var(--colorBg)!important;
    color: var(--colorText)!important;
  }
  :global(body) {
    font-family: 'Questrial'!important;
  }

  :global(.I){
    color:var(--brandColor);
  }

  :global(.btn-global) {
    background-color:var(--buttonBgColor)!important;
    border-color:var(--buttonBgColor)!important;
    color:var(--buttonTextColor)!important;
  }
  :global(.btn-global:hover) {
    opacity:0.8;
  }
  :global(.btn-outline-global) {
    border-color:var(--buttonBgColor)!important;
    color:var(--buttonBgColor)!important;
  }
  :global(.btn-outline-global:hover) {
    opacity:0.8;
    background-color:var(--buttonBgColor)!important;
    color:var(--buttonTextColor)!important;
  }
  
  :global(.link-global) {
    color:var(--colorLink)!important;
  }

  :global(::-webkit-scrollbar) {
    width: 20px!important;
    height: 20px!important;
  }

  @media only screen and (max-width: 600px) {
    :global(::-webkit-scrollbar) {
      width: 5px!important;
      height: 5px!important;
    }
  }

  :global(::-webkit-scrollbar-thumb) {
    background: var(--brandColor)!important;
  }

  :global(.dropdown-menu) {
    padding: 0;
  }

  .footer {
    margin-top:0.5rem;
    z-index:2;
    background-color: var(--navbarBgColor);
    box-shadow: 0 0.5rem 1rem var(--navbarBgColor);
    border-color: rgba(0, 0, 0, 0);
  }

  :global(.cards) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: auto;
    grid-gap: 2rem;
  }

  :global(.anchor span) {
    color:var(--brandColor2);
    cursor:pointer;
  }
  :global(.anchor span:hover){
    font-weight:bold;
  }

  :global(.alert-info) {
    --bs-alert-color: #055160;
    --bs-alert-bg: #f0fcff;
    --bs-alert-border-color: #f0fcff;
  }
  :global(.alert-warning) {
    --bs-alert-color: #055160;
    --bs-alert-bg: #fff4cb;
    --bs-alert-border-color: #fff4cb;
  }
  :global(.alert-success) {
    --bs-alert-color: #055160;
    --bs-alert-bg: #dcffd5;
    --bs-alert-border-color: #dcffd5;
  }


</style>

<svelte:head>
  <link href='https://fonts.googleapis.com/css?family=Questrial' rel='stylesheet'>
</svelte:head>
