<script>
  import { conf, lang, permissions, alert } from './stores.js'
  import { apiGet, apiPost, apiDelete, translate, i18n, setRoute, locale } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import Route from './G_Route.svelte'
    
  let objs=[], obj={}, keyword="", selects=[]

  async function add(){
    setRoute("/translations/edit")
  }

  async function update(){
    await apiPost(`/i18n`, obj)
    list()
    setRoute("/translations")
  }

  async function get(id){
    if(id) obj=await apiGet(`/i18n/${id}`)
    else obj={}
  }

  async function list(){
    selects=[]
    objs = await apiGet(`/i18n`, {keyword:keyword},{spinner:true})
  }

  async function del(){
    await apiDelete(`/i18n/${obj.id}`)
    list()
    setRoute("/translations")
  }
  async function dels(){
    await apiDelete(`/i18n`, {ids: selects})
    list()
    setRoute("/translations")
  }
  
  
  const apply=async ()=>{
    $conf.i18n=await apiGet('/i18n/reload')
    $conf=$conf
    let langwas=$lang
    $lang="";
    $lang=langwas
    $alert.success="Updated"
  }

  let useFreeTranslate=true
  const doTranslate=async (targetLang)=>{
    let ret = await translate(obj.lang, obj[obj.lang], targetLang, useFreeTranslate)
    obj[targetLang]= ret
  }
  const doTranslateAll=async ()=>{
    $conf.langs.forEach(async (langKey,i) => {
      if(obj.lang!=langKey) obj[langKey]=await translate(obj.lang, obj[obj.lang], langKey, useFreeTranslate)
    })
  }
</script>

<Route path="/translations/*" pathHide="/translations/+" fsContent valid={$permissions.isVendor}>
  <div class="container-fluid fsContent">
    
    <Title title back/>
    <div class="row">
      <Button {add} class="col-auto"/>
      <Button {dels} class="col-auto" title={`delete {1} selected§${selects.length}`} disabled={!selects.length}/>
      <Button {apply} class="col-auto"/>
      <Input class="my-1 col" bind:value={keyword} type="text"/>
    </div>

    <div class="fs-5 my-1">{objs.length} {i18n("g--Translations")}</div>
    <div class="fsScroll">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="pointer hover" on:click={()=>{selects=selects.length?[]:objs?.map(obj => obj.id)}}>☐</th>
            <th scope="col">#</th>
            <th scope="col">App side</th>
            {#each $conf.langs as lang}
              <th scope="col">{lang}</th>
            {/each}
          </tr>
        </thead>
        <tbody>
          {#await list(keyword) then}
            {#each objs as translation (translation)}
              <tr on:click={()=>setRoute(`/translations/edit/${translation.id}`)}>
                <th on:click|stopPropagation scope="col"><input type="checkbox" bind:group={selects} value={translation.id}/></th>
                <th scope="row">{translation.msgId}</th>
                <td>{#if translation.appSide}<I mobile/>{/if}</td>
                {#each $conf.langs as lang}
                  <td>{#if translation[lang]}<I check success/>{:else}<I exclamation-triangle warning/>{/if}</td>
                {/each}
              </tr>
            {/each}
          {:catch}
          <Wrong/>
        {/await}
        </tbody>
      </table>
        
    </div>
    
  </div>
</Route>


<Route path="/translations/edit/:id?" let:routeParams fsContent valid={$permissions.isVendor}>
  {#await get(routeParams.id) then}
    <div class="container-fluid fsContent">
      <Title title="Translation" detail={obj.msgId} back="/translations"/>
      
      <div class="fsScroll">
        <Form>
          <Input class="col-lg-6" bind:value={obj.msgId} type="text" icon label="Msg id" required button={{action: doTranslateAll, icon:"globe"}}/>
          <Input set="en" oldnotusedset={$conf.langs[0]} class="col-lg-4"  bind:value={obj.lang} type="select" options={$conf.langs} icon="fa-language" label="base lang"/>
          <Input class="col-lg-2" set={true} bind:value={obj.appSide} type="switch" icon="fa-mobile" label="app side" option="yes"/>
        
          {#each $conf.langs as lang}
            <Input class="col-lg-6" bind:value={obj[lang]} type="textarea" icon="fa-language" label={lang} button={lang!=obj.lang?{action:()=>{doTranslate(lang)}, icon:'globe'}:false}/>
          {/each}
          <div class="row">
            {#if obj.id}
              <Button validity {del} class="col-auto"/>
              <Button validity {update} class="col-auto"/>
            {:else}
              <Button validity {update} actionModel="save" class="col-auto"/>
            {/if}
          </div>
        </Form>
      </div>
    </div>
  {:catch}
    <Wrong/>
  {/await}
</Route>

<style>
  tbody tr:hover{
    background-color:var(--navbarBgColor);
    cursor: pointer;
  }

  table thead{
    position: sticky;
    top: 0;
    background-color:white;
    border-bottom: 2px solid currentColor;
  }
</style>
