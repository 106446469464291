<script>
  import { route, menus, user, customerId, customer } from './stores.js'
  import { setRoute, daysLeft, matchRoute } from './h.js'
  import I18n from './G_I18n.svelte'

  function CTA(){
    setRoute("/account/licence")
  }

  $: showCustomerBanner=$menus.find(menu => menu.customerSelector && matchRoute($route, menu.route+'/*'));
</script>

{#if showCustomerBanner}
  {#if $customer.licence=='TRIAL' && !$customer.expired}
    <div class="fs-5 p-1 bg-warning text-center text-white">
      <I18n>licence--Trial banner</I18n> - 
      {daysLeft($customer.expireDate)} <I18n>licence--days left</I18n>
      <button on:click={CTA} class="ms-2 btn btn-xs btn-outline-success"><I18n>licence--Upgrade</I18n></button>
    </div>
  {/if}

  {#if $customer.expired}
    <div class="fs-5 p-1 bg-danger text-center text-white">
      <I18n>licence--Expired</I18n>
      <button on:click={CTA} class="ms-2 btn btn-xs btn-warning"><I18n>licence--Renew</I18n></button>
    </div>
  {/if}
{/if}