<script>
  import { user, userId, alert, conf } from './stores.js'
  import { apiGet, apiPost, setRoute } from './h.js'
  import Alert from './G_Alert.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import Title from './G_Title.svelte'

  let obj = {}

  async function getUser() {
    obj = await apiGet(`/users/me`)
  }

  async function setUser() {
    let ret = await apiPost(`/users/profil`, obj)
    if (ret) {
      $alert.success = "Updated"
      $user = { ...$user, ...obj }
      setRoute()
    }
    else $alert.danger = "Error"
  }

  async function proposePassword() {
    return await apiGet(`/auth/genPwd`)
  }
</script>

<div class="container fsContent">
  <Title title="Profil" back />
  <div class="fsScroll">
    {#await getUser() then}
    <Form>
      <Input bind:value={obj.email} type="email" icon label="email" required />
      <Input bind:value={obj.name} label="name" required icon />
      <Input bind:value={obj.password} type="password" {proposePassword} pattern showStrength showPassword
        label="password" icon />
      {#if $conf.sign2FA}
        <Input set={false} lg type="switch" option="2FA" bind:value={obj.is2fa} />
      {/if}
      <Button validity action={setUser} actionModel="save" class="col-auto" />
    </Form>
    {:catch}
    <Wrong />
    {/await}
  </div>
</div>