<script>
  import { w, lang, menus, route } from './stores.js'
  import { i18n, setRoute, matchRoute } from './h.js'
  
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'

  export let back=false
  export let cancel=false
  export let title=false
  export let detail=false
  export let help=false
  export let noCompact=false
  
  $: isCompact=!noCompact && $w.media=="xs"?true:false

  let menuTitle=""
  $: if($menus.length && title===true) $menus.forEach(menu => {
    if(!menuTitle) menuTitle=(menu.route==$route || matchRoute($route, menu.route + '/*'))?menu.name:""
  })

  $: msize=isCompact?1:2

</script>

<!-- back button -->
{#if back}
  <div class:isCompact class="my-{msize} d-flex flex-wrap align-items-center">
    {#if back}
      <button class="mb-1 me-{msize} btn btn-global" on:click={()=>setRoute(back===true?"":back)}>
        <I  solid circle-arrow-up-left me={!isCompact}/>
        {#if !isCompact}<span><I18n id="g--Back"/></span>{/if}
      </button>
    {/if}
    <span class="title ucfirst">{@html i18n(menuTitle || title, $lang)}</span>
    {#if detail}<span class="detail ucfirst">&nbsp;/&nbsp;{i18n(detail, $lang)}</span>{/if}
  </div>
{/if}

<!-- cancel button -->
{#if cancel}
  <div class:isCompact class="my-1 d-flex flex-wrap align-items-center align-items-center">
      <button class="btn-sm me-{msize} btn btn-outline-dark" on:click={()=>setRoute(cancel===true?"":cancel)}>
        <I  solid circle-xmark/>
        <I18n id="g--Cancel"/>
      </button>
    <span class="titleBack ucfirst">{@html i18n(menuTitle || title, $lang)}</span>
    {#if detail}<span class="detail ucfirst">&nbsp;/&nbsp;{i18n(detail, $lang)}</span>{/if}
  </div>
{/if}

{#if false && detail}
  <span class:isCompact class="d-flex flex-wrap align-items-center">
    <span class="detail ucfirst">{i18n(detail, $lang)}</span>
  </span>
{/if}
{#if help}
  <div class="alert alert-warning" >
    {@html i18n(help, $lang)}
  </div>
{/if}
<style>
  .title{
    font-size:1.5rem;
    font-weight: bold;
    line-height:2rem;
  }
  .titleBack{
    font-size:1.2rem;
    font-weight: bold;
  }
  .detail{
    font-size:1.5rem;
    font-weight: lighter;
  }
  .isCompact>*{
    font-size:1rem!important;
    line-height:1rem!important;
  }
</style>

