<script>
  import { conf, lang, customerId, token } from './stores.js'
  import { apiGet, i18n, setRoute, dateCalculate } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import Chart from './G_Chart.svelte'
  import DatesPicker from './G_DatesPicker.svelte'
  import FilterLine from './G_FilterLine.svelte'

  let today = new Date()
  //let startDate = dateCalculate(today, -30, 'day', 'datePart')
  let startDate = dateCalculate(today, 0, 'firstDayOfMonth', 'datePart')
  let todayDate = dateCalculate(today, 0, 'day', 'datePart')
  let endDate = todayDate


  $: preDates = [
    { name: 'Last 90 days', startDate: dateCalculate(today, -90, 'day', 'datePart'), endDate: todayDate },
    { name: 'Last 30 days', startDate: dateCalculate(today, -30, 'day', 'datePart'), endDate: todayDate },
    { name: 'Last 7 days', startDate: dateCalculate(today, -7, 'day', 'datePart'), endDate: todayDate },
    { name: 'This week', startDate: dateCalculate(today, 0, 'firstDayOfWeek', 'datePart'), endDate: todayDate },
    { name: 'Yesterday', startDate: dateCalculate(today, -1, 'day', 'datePart'), endDate: dateCalculate(today, -1, 'day', 'datePart') },
    { name: 'Today', startDate: todayDate, endDate: todayDate },
    { name: 'Last year', startDate: dateCalculate(today, -1, 'firstDayOfYear', 'datePart'), endDate: dateCalculate(today, -1, 'lastDayOfYear', 'datePart') },
    { name: 'This year', startDate: dateCalculate(today, 0, 'firstDayOfYear', 'datePart'), endDate: todayDate },
    { name: 'Last month', startDate: dateCalculate(today, -1, 'firstDayOfMonth', 'datePart'), endDate: dateCalculate(today, -1, 'lastDayOfMonth', 'datePart') },
    { name: 'This month', startDate: dateCalculate(today, 0, 'firstDayOfMonth', 'datePart'), endDate: todayDate },
    { name: 'Last week', startDate: dateCalculate(today, -1, 'firstDayOfWeek', 'datePart'), endDate: dateCalculate(today, -1, 'lastDayOfWeek', 'datePart') },
    //{name:'All', startDate: dateCalculate(firstTracking, 0, 'day', 'datePart') ,endDate: todayDate}
  ]

  $: getAnalytics(startDate, endDate)

  let analytics = {}

  async function getAnalytics() {
    analytics = await apiGet(`/customer/${$customerId}/analytics/${startDate}/${endDate}`)
    if (analytics) analytics.ok = true
    else analytics = {}
  }

  function exportAnalytics(){
    window.open(`/api/v5/customer/${$customerId}/analytics/${startDate}/${endDate}/export?token=${$token}`)
  }

  $: analytics = [
    { name: 'Badges créés', icon: 'plus', value: analytics.carsCreated || 0, color: "success", valid: true },
    { name: 'Badges prolongés', icon: 'refresh', value: analytics.carsProlongated || 0, color: "success", valid: true }
  ]

  let c = {
    lightblue: '#00FFB6',
    green: '#1AD604',
    red: '#F9344F',
    purple: '#9C27B0',
    blue: '#2F9BF4',
    orange: '#FF7903',
    pink: '#df01d8'
  }
</script>
<Route path="/statistics" fsContent>

  <div class="container fsContent">

    <Title title back />

    <div class="line my-1">
      <div>
        {#each preDates as preDate}
        <div class:active={startDate==preDate.startDate && endDate==preDate.endDate} on:click={()=>{startDate=preDate.startDate; endDate=preDate.endDate}}><I18n>analytics--{preDate.name}</I18n>
        </div>
        {/each}
      </div>
    </div>

    <div class="row">
      <div class="col">
        <DatesPicker bind:startDate bind:endDate maxDate={new Date()} />
      </div>
      <div class="col-auto">
        <Button noMargin action={exportAnalytics} color="secondary" title="Export statistiques"/>
      </div>
    </div>

    <div class="fsScroll">
      <div class="row row-cols-1 row-cols-md-4 g-4">

        {#each analytics as d}
        {#if d.valid}
        <div class="col" on:click={()=>{if (d.route) setRoute(d.route)}}>
          <div class="card h-100 shadow pointer" class:stop={!d.route}>
            <div class="card-body">
              <div class="card-analytics">

                {#if d.type=="right"}
                <div>
                  <div class="text-{d.color}">{d.value}</div>
                  <div class="text">{i18n(d.name, $lang)}</div>
                </div>
                <div class="text-{d.color}"><I icon={d.icon} /></div>
                {:else}
                <div class="text-{d.color}"><I icon={d.icon} /></div>
                <div class="text-end">
                  <div class="text-{d.color}">{d.value}</div>
                  <div class="text">{i18n(d.name, $lang)}</div>
                </div>
                {/if}
              </div>
            </div>
          </div>
        </div>
        {/if}
        {/each}
      </div>

    </div>
  </div>
</Route>

<style>
  .line {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 2px solid #dee2e6;
  }

  .line :global(>div) {
    display: flex;
    flex-wrap: wrap;
  }

  .line :global(>div>div.active) {
    border-bottom: 4px solid var(--brandColor);
  }

  .line :global(>div>div:hover) {
    border-bottom: 4px solid var(--brandColor2);
  }

  .line :global(>div>div) {
    white-space: nowrap;
    padding-bottom: 2px;
    cursor: pointer;
    margin-right: 1rem;
    border-bottom: 4px solid transparent;
  }


  .card-analytics {
    line-height: 2.5rem;
    font-size: 3rem;
    display: flex;
    justify-content: space-between;
  }

  .text {
    line-height: 1rem;
    font-size: 1rem;
  }
</style>