<script>
  import { conf } from './stores.js'
  
  import I18n from './G_I18n.svelte'

  export let label=""
  export let save=false
  export let update=false
  export let ok=false
  export let apply=false
  export let add=false
  export let create=false
  export let back=false
  export let del=false
  export let dels=false
  export let close=false
  export let cancel=false
  export let send=false
  export let previous=false
  export let next=false
  export let test=false

  export let cond=true /* helper just to show button if cond is true*/

  export let title=''
  export let icon=''
  export let iconRight=''
  export let btnGlobal=false
  export let lg=false
  export let sm=false
  export let action=false
  export let color='' 
  export let validity=false
  export let actionModel=false
  export let disabled=false
  export let hideTitleXs=false
  export let noMargin=false
  export let noLabel=false //reserve space as a a label

  if(disabled) validity=false

  let cl=$$props.class || ''

  let faType=$conf.faType || 'fa-light'
  
  let actions={
    save:{color:'success', icon: 'save'},
    update:{color:'success', icon: 'save'},
    ok:{color:'success', icon: 'check'},
    apply:{color:'success', icon: 'check'},
    add:{color:'success', icon: 'plus'},
    create:{color:'success', icon: 'plus'},
    back:{color:'primary', icon: 'angle-left'},
    delete:{color:'danger', icon: 'trash'},
    deletes:{color:'danger', icon: 'trash'},
    close:{color:'primary', icon: 'times'},
    cancel:{color:'secondary', icon: 'times'},
    previous:{color:'secondary', icon: 'angle-left'},
    next:{color:'secondary', icon: 'angle-right', iconRight:true},
    test:{color:'warning', icon: 'badge-check'},
  }

  if(!action){
    if(update) {actionModel=actionModel || 'update'; action=update}
    if(save) {actionModel=actionModel || 'save'; action=save}
    if(ok) {actionModel=actionModel || 'ok'; action=ok}
    if(apply) {actionModel=actionModel || 'apply'; action=apply}
    if(add) {actionModel=actionModel || 'add'; action=add}
    if(create) {actionModel=actionModel || 'create'; action=create}
    if(send) {actionModel=actionModel || 'send'; action=send}
    if(back) {actionModel=actionModel || 'back'; action=back}
    if(del) {actionModel=actionModel || 'delete'; action=del}
    if(dels) {actionModel=actionModel || 'delete'; action=dels}
    if(close) {actionModel=actionModel || 'close'; action=close}
    if(cancel) {actionModel=actionModel || 'cancel'; action=cancel}
    if(previous) {actionModel=actionModel || 'previous'; action=previous}
    if(next) {actionModel=actionModel || 'next'; action=next}
    if(test) {actionModel=actionModel || 'test'; action=test}
  }

  if(!action) action = Object.values($$props).find(value => typeof value === 'function');

  if(actions[actionModel]){
    if(!icon) icon=actions[actionModel].icon
    if(!color && !btnGlobal) color=actions[actionModel].color
    if(!title && title!==false) title="g--" + actionModel
    if(iconRight!==false) iconRight=actions[actionModel].iconRight
  }
  
  if(!color) color='primary'
  if(!title && title!==false) title="g--ok"

</script>

{#if cond}
  <div class="G_Button {cl}" class:my-1={!noMargin}>
    {#if label || noLabel===true}<label for="" class="form-label text-wrap">{label || " "}</label>{/if}
    <button type="button" class="w-100 btn btn-{color}" class:btn-global={btnGlobal} class:btn-sm={sm} class:btn-lg={lg} {disabled} {validity} on:click={action}>
      {#if icon && !iconRight}
        <i class="{faType} fa-{icon}"/>
      {/if}
      {#if title}
      <span class:d-none={hideTitleXs} class:d-sm-inline={hideTitleXs}>
        <span class="ucfirst ms-2 me-2"><I18n id={title}/></span>
      </span>
      {/if}
      {#if icon && iconRight}
        <i class="{faType} fa-{icon}"/>
      {/if}
    </button>
  </div>
{/if}

<style>
  :global(.auto>button){
    width:unset!important;
  }
  .form-label {
    margin-bottom: 1.35rem;
  }
</style>