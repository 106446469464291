<script>
  import { userId, customerId, route, routeReload, permissions } from './stores.js'
  import { setRoute, matchRoute, isRoute, fuc, checkPermsMenu } from './h.js'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  export let menus=[]
  export let noAutoselect=false

  import { tick } from 'svelte';


  $: setLineMenuRoute($permissions, menus)
    

  async function setLineMenuRoute(){
    await tick() //wait for menus to be updated when using Local menu (ie : L_Account)
    //console.log(menus)
    if(noAutoselect) return
    if(!$userId) return
    let firstValid
    for (let menu of menus) {
      if(checkPermsMenu(menu, $permissions)){
        if(!firstValid) firstValid=menu.route
        if(menu.route==$route) return
      }
    }
    if(firstValid) setRoute(firstValid)
  }
</script>


<div class="line my-1">
  <div>
    {#key menus}
      {#each menus as menu}
        {#if checkPermsMenu(menu, $permissions)}
          <div class:active={ matchRoute($route, [menu.route + "/*", menu.route + "*"])} on:click={()=>setRoute(menu.route, null, null, true)}>
            {#if menu.icon}<I icon={menu.icon}/>{/if}
            {#if menu.name}<I18n id={fuc(menu.name)}/>{/if}
          </div>
        {/if}
      {/each}
    {/key}
  </div>
</div>

<style>
  .line {  
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 2px solid #dee2e6;
  }

  .line :global(>div) {  
    display: flex;
    flex-wrap: wrap;
  }

  .line :global(>div>div.active) {
    border-bottom: 4px solid var(--brandColor);
  }
  .line :global(>div>div:hover){
    border-bottom: 4px solid var(--brandColor2);
  }

  .line :global(>div>div) {
    white-space: nowrap;
    padding-bottom: 2px;
    cursor: pointer;
    margin-right: 1rem;
    border-bottom: 4px solid transparent;
  }
</style>