<script>
  import { conf, lang, customerId } from './stores.js'
  import { beep, beepWrong, apiGet, dateTimeFormat, fuc, getById } from './h.js'

  import I18n from './G_I18n.svelte'
  import Scanner from './G_Scanner.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'

  let countriesNamed = $conf.countries.map(country => ({ ...country, name: `${country.symbol} - ${country.name} ` }))
  let countriesSorted = countriesNamed.sort((a, b) => a.name.localeCompare(b.symbol));

  let car
  let status = "WAIT SCAN"
  let keyword = ""
  let country

  const colors = { resident: "blue", professional: "pink" }

  async function onCodeRead(code) {
    status = "WAIT SCAN"
    car = null
    code = code?.includes('/p/') ? code.split('/p/')[1] : code; //we can match an url like "https://xxxx.yyy/c/CUSTOMERSHORTID/p/CARSHORTID"   OR just the car.shortId
    search(code)
  }

  async function search(code, manual) {
    if (!code) return
    car = await apiGet(`/customer/${$customerId}/cars/scan/${encodeURIComponent(code)}/${country}`)
    if (car?.id) {
      if (!car?.validityDate || new Date(car.validityDate) < new Date()) status = "EXPIRED"
      else status = "VALID";
    } else status = "INVALID";
    if (status != "VALID" && isBeep) beepWrong()
    else if (manual && isBeep) beep()
    if (manual) setTimeout(() => {
      keyword = ""
      country = undefined
      onCodeRead(false)
    }, 10000)
  }

  let isBeep = true
</script>

<div class="mt-2">
  <div class="container m-auto" style="max-width:600px;">
    <Form>
      <Input class="col-8" bind:value={keyword} placeholder="scan--search" />
      <Input class="col-4" set="FR" bind:value={country} type="select" options={countriesSorted} />
      <Button class="col-10 mb-2" action={()=>{search(keyword, true)}} icon="magnifying-glass" title={false} noMargin btnGlobal/>
        <Button class="col-2  mb-2" color="outline-warning" action={()=>{beep();isBeep=!isBeep;}} icon={isBeep?"volume":"volume-slash"} title={false} noMargin/>
    </Form>


    <div class="h3 py-3 text-white text-center" class:bg-info={status=="WAIT SCAN" } class:bg-success={status=="VALID" } class:bg-danger={status=="INVALID" || status=="EXPIRED" }>
      <div><strong>
          <I18n id={`scan--${status}`} />
        </strong></div>
      {#if car?.plate}
      <div class="d-flex justify-content-center align-items-center fw-bold border border-5 rounded bg-white border-dark mx-4 my-1 text-dark p-1">
        {car.plate}
        {#if car?.country}
        <span class="ms-4 fw-lighter fs-6 badge rounded-pill text-bg-secondary">{getById($conf.countries, car.country, "value")?.symbol}</span>
        {/if}
      </div>
      {/if}
      {#if car?.type}
      <h1><span class="badge bg-secondary" style:background-color|important="{`var(--bs-${colors[car?.type]})`}">
          <I18n id={fuc(car?.type)} />
        </span></h1>
      {/if}
      <div>{dateTimeFormat(car?.validityDate || "","long")}</div>
    </div>

    <Scanner {onCodeRead} beepOnScan={isBeep} autoClear autoClearTimeout=5000 autoSameCodeTimeout=500 />
  </div>
</div>