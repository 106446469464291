<script>
  import { conf, online, wscOnline, lang } from './stores.js'
  import { atobUnicode } from './h.js'
  import Start from './L_Start.svelte'
  export let context = {}
  export let inSSR = false

  if (typeof window !== 'undefined' && window.context) {
    context = JSON.parse(atobUnicode(window.context))
  }
  $conf = context.conf || {}
  let navigatorOnline = false
  $: $online = ($wscOnline == -1 && navigatorOnline) || ($wscOnline && navigatorOnline)
</script>
<Start {inSSR}/>
<svelte:window bind:online={navigatorOnline} />
<svelte:head>
  <meta name="theme-color" content="{$conf.metaThemeColor || 'grey'}">
</svelte:head>