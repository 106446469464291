<script>
  import { conf, permissions, modal, customerId } from './stores.js'
  import { apiGet, apiPost, apiDelete, i18n, setRoute, anchor, debounce } from './h.js'
  import Title from './G_Title.svelte'
  import Form from './G_Form.svelte'
  import Input from './G_Input.svelte'
  import Button from './G_Button.svelte'
  import Wrong from './G_Wrong.svelte'
  import I from './G_I.svelte'
  import I18n from './G_I18n.svelte'
  import Route from './G_Route.svelte'
  import CustomerBanner from './G_CustomerBanner.svelte'

  import FilterLine from './G_FilterLine.svelte'
  import List from './G_List.svelte'

  let objs = [], obj = {}, keyword = "", sortBy = { name: 1 }, filter = {}, tagsSelected = []

  async function edit(id) {
    setRoute(`/streets/edit/${id}`)
  }

  async function update() {
    await apiPost(`/customer/${$customerId}/streets`, obj)
    list()
    getTags()
    setRoute("/streets")
  }

  async function get(id) {
    obj = {}
    if (id) obj = await apiGet(`/customer/${$customerId}/streets/${id}`)
  }

  const list = debounce(listD, 200);
  async function listD() {
    let existentTags = tagsSelected ? tagsSelected.filter((t) => { return tags.includes(t) }) : false
    let req = { ...filter, ...(existentTags.length ? { tags: { $in: existentTags } } : {}) }
    objs = await apiGet(`/customer/${$customerId}/streets`, { filter: req, keyword: keyword, sortBy: sortBy, searchFields: ["name", "type"] })
  }

  async function del() {
    $modal = {
      cancel: true, title: `${i18n("g--Delete")} ${i18n(obj.name)}`, msg: i18n("g--Confirm delete"), color: 'danger', dangerZone: 'yes', confirm: async () => {
        await apiDelete(`/customer/${$customerId}/streets/${obj.id}`)
        list()
        setRoute("/streets")
      }
    }
  }


  let tags = []
  //getTags()
  async function getTags() {
    tags = await apiGet(`/customer/${$customerId}/streets/tags`)
  }

  let optionsType = ["ALLEE", "AVENUE", "IMPASSE", "MARCHE", "PLACE", "RUE", "SQUARE", "VILLA", "VOIE COMMUNALE"]
</script>


<Route path="/streets/*" pathHide="/streets/+" fsContent>
  <div class="container-fluid fsContent">

    <Title title withCustomerSelector back />

    <FilterLine {tags} bind:tagsSelected search bind:keyword createRoles={["c_admin","c_manager"]} create={()=>
      setRoute("/streets/edit")}
      bind:filter filters={[
      { filter: {}, title:'g--All' },
      { filter: {isBlue:true}, title:'g--blue zone' }
      ]}

      bind:sortBy sortBys={[
      { id:'name', title:'g--By name'},
      { id:'type', title:'g--By type', order:-1},
      { id:'isBlue', title:'g--blue zone', order:-1},
      { id:'count', title:'g--counter', order:-1}
      ]}
      />
      {#await list(keyword, filter, sortBy) then}
      <List onSelect={edit} refresh={list} title="g--streets" {objs} fields={[ {id:"name", title:"g--name"}, {id:"type",
        title:"g--type"}, {id:"isBlue", title:"g--blue zone"}, {id:"count", title:"g--counter"} ]} />
      {/await}
  </div>
</Route>


<Route path="/streets/edit/:id?" let:routeParams fsContent>
  {#await get(routeParams.id) then}

  <div class="container fsContent">
    <Title title detail={obj.name} back="/streets" />

    <!--div class="anchor" on:click={anchor}>
        <span><I18n>Description</I18n></span>
      </div-->

    <Form fsContent norow>
      {#if obj.id}
      <div class="d-flex justify-content-end">
        <Button validity {update} class="col-auto" />
      </div>
      {/if}
      <div class="row fsScroll">
        <Input bind:value={obj.name} required type="text" icon label="Name" />

        <Input bind:value={obj.type} type="select" required icon="fa-building" label="Type" options={optionsType}/>
        <Input set={false} type="switch" lg option="Blue Zone" bind:value={obj.isBlue} />
        <Input bind:value={obj.count} disabled type="number" label="Counter" />

        <div class="row">
          {#if obj.id}
          <Button validity {del} class="col-auto" />
          <Button validity {update} class="col-auto" />
          {:else}
          <Button validity {update} actionModel="save" class="col-auto" />
          {/if}
        </div>
      </div>
    </Form>
  </div>
  {:catch}
  <Wrong />
  {/await}
</Route>